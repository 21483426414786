<template>
  <div>
    <v-container>
      <v-row class="text-center">
        <v-col cols="12">
          <v-img :src="logo" class="my-3" contain height="200" />
        </v-col>

        <v-col class="mb-4">
          <h1 class="display-2 font-weight-bold mb-3">Born Again Strength</h1>

          <v-btn text :to="{ name: 'Egg2Alert' }"
            >"Train different, Be different."</v-btn
          >
        </v-col>
      </v-row>

      <v-row class="level1">
        <v-col cols="12" md="4">
          <v-card hover class="mx-auto" max-width="300">
            <v-img
              class="white--text align-end"
              max-height="350"
              :src="sydneystone2"
            >
            </v-img>
            <v-card-text class="text--primary text-center">
              <div>Owners of Born Again Strength</div>
              <div>Stone & Sydney Osborne</div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="8">
          <h3>History</h3>
          <p>
            Stone and Sydney Osborne founded Born Again Strength in July of
            2019. Born Again Strength began as a small strength and conditioning
            gym and was able to quickly expand into a fully equipped baseball
            facility with strength and conditioning services.
          </p>

          <h3>Mission</h3>
          <p>
            Our mission at Born Again Strength is to help athletes excel in
            their sport, accomplish their goals, and prepare for all levels of
            athletics through sport-specific strength training and skill
            development.
          </p>

          <h3 class="mt-3">Values</h3>
          <p>
            We use Christian based values to build a training environment that
            is:
          </p>
          <ul>
            <li>Fun</li>
            <li>Safe</li>
            <li>Competitive</li>
          </ul>

          <p class="mt-3">
            We believe the core principals of
            <strong>Integrity, Hardwork, Leadership</strong> and
            <strong> Sportsmanship</strong>
            are invaluable to an athlete’s development both on and off the
            field.
          </p>
        </v-col>
      </v-row>

      <v-row class="level2">
        <v-col cols="12">
          <h3>Stone</h3>
          <p>
            Stone Osborne was born and raised in Owenton, Kentucky and attended
            University of the Cumberlands. He was on the baseball team and
            graduated with his Bachelor of Science in Exercise and Sport Science
            and Biology. He continued his baseball career playing for the
            Trinidad Triggers and the Utica Wooly Mammoths. Stone is the head
            baseball instructor and strength coach for Born Again Strength.
          </p>

          <h3>Sydney</h3>
          <p>
            Sydney Osborne was born and raised in Lebanon, Ohio and attended the
            University of the Cumberlands as well. She earned a scholarship and
            was a 4-year starter for the volleyball team. She graduated with her
            Master of Science in Health and Human Performance, as well as her
            Bachelor of Science in Business Administration and Fitness and Sport
            Management. Sydney is the general manager and strength coach for
            Born Again Strength.
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import sydneystone2 from '@/assets/images/sydneystone2.jpg'
import logo from '@/assets/images/5Logo.svg'

export default {
  data() {
    return {
      tab: null,
      items: ['Appetizers', 'Entrees', 'Deserts', 'Cocktails'],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      sydneystone2: sydneystone2,
      logo: logo,
    }
  },
}
</script>

<style></style>
