<template>
  <div>
    <StaffInfo />
  </div>
</template>

<script>
import StaffInfo from '@/components/StaffInfo.vue'

export default {
  name: 'AboutUs',
  components: {
    StaffInfo,
  },

  data() {
    return {}
  },
}
</script>

<style></style>
